import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import cn from "classnames";
import useDarkMode from "use-dark-mode";

import { SkeletonWatchList } from "components";
import {
  ActiveCurrencyState,
  ActiveAssetTab,
  StockState,
  WatchlistState,
  ForexState,
  PrivatesState,
  CommoditiesState,
  MusicState,
  SbaState, ManuHomeLoanState,
  initialQueryMHLoanState,
  initialQuerySbaState,
  initialQueryPrivateState,
  initialQueryEFLoanState,
  EquipmentFinanceLoanState,
} from "states";
import { useNetwork, useIndexedDB, INIT_CONFIGURATION_SAVE } from "hooks";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";

import { Assets } from "../assets";
import {
  assestType,
  ACTIVE_ASSET_TAB_WITH_SPACE,
  assetsTab,
  assetTabs,
  assetTabsInclude,
  defaultActiveCurrency,
  SBA7A
} from "../../constants";
import { CurrencySearch } from "./components";
import { Image } from "@storybook";
import styles from "./Currency.module.sass";
import { IAssetsState } from "../../../../states/exchange/type";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { removeDuplicatesAndEmptyIds } from "@utils/objectOperations";
// @ts-ignore
import { Json } from "@types/common";
import { useWebSocket } from "@hooks/web-socket";

export const Currency = () => {
  //global states
  const [activeTab, setActiveTab] = useRecoilState(ActiveAssetTab);
  const isManuHomeLoan = activeTab.key === assetTabs.MHL
  const [watchList, setWatchList] = useRecoilState(WatchlistState);
  const setStocks = useSetRecoilState(StockState);
  const [sba, setSba] = useRecoilState(SbaState);
  const [manuHomeLoan, setManuHomeLoan] = useRecoilState(ManuHomeLoanState);
  const [equipmentFinance, setEquipmentFinance] = useRecoilState(EquipmentFinanceLoanState);
  const [musics, setMusics] = useRecoilState(MusicState);
  const setForex = useSetRecoilState(ForexState);
  const [privateState, setPrivatesState] = useRecoilState(PrivatesState);
  const [activeCurrency, setActiveCurrency] =
    useRecoilState(ActiveCurrencyState);
  const setCommoditiesState = useSetRecoilState(CommoditiesState);
  const resetMHLFilters = useResetRecoilState(initialQueryMHLoanState);
  const resetEFFilters = useResetRecoilState(initialQueryEFLoanState);
  const resetSba7Filters = useResetRecoilState(initialQuerySbaState);
  const resetPrivateFilters = useResetRecoilState(initialQueryPrivateState);
  //const setISMusicConfig = useSetRecoilState(ISMusicConfigState);
  const promiseAllState = useRef(false);
  const darkMode = useDarkMode(false);
  const [assetLoading, setAssetLoading] = useState(false);
  const { errorNotification } = useNotification();

  const prevPrivateState = useRef<any>([]);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { socket } = useWebSocket();
  const currencyNavigationRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<(HTMLButtonElement | null)[]>([])

  useEffect(() => {
    if (privateState?.length) {
      const newPrivateState = removeDuplicatesAndEmptyIds(privateState);
      // Check if the length of the array has changed

      if (newPrivateState.length !== prevPrivateState.current.length) {
        setPrivatesState(newPrivateState);
      }
      // Update the previous state reference
      prevPrivateState.current = newPrivateState;
    }
  }, [privateState, setPrivatesState]);

  useEffect(() => {
    if (
      activeCurrency.type === "privates" &&
      privateState?.length > 0 &&
      params.id
    ) {
      const findIndex = privateState?.findIndex((asset: any) => {
        return asset?.id === activeCurrency?.id;
      });
      if (findIndex === -1 && activeCurrency?.id) {
        setPrivatesState((prev) => {
          return [activeCurrency, ...prev];
        });
      }
    }
  }, [privateState, activeCurrency, params.id]);

  useEffect(() => {
    if (activeTab?.key === "privates")
      setActiveTab({ key: "Private Stock", index: 1 });
  }, [activeTab, setActiveTab]);

  const {
    get: getWatchLists,
    data: watchLists,
  } = useNetwork();

  const { get: getStocks, data: stockData } = useNetwork();
  const { get: getMusic, data: musicData } = useNetwork();
  const { get: getSba, data: sbaData } = useNetwork();
  const { get: getManuHomeLoan, data: manuHomeLoanData } = useNetwork();
  const { get: getEquipmentFinanceLoan, data: equipmentFinanceLoanData } = useNetwork();
  const { get: getPrivates, data: privatesData } = useNetwork();
  const { get: getCommodites, data: commoditesData } = useNetwork();
  const { get: getForex, data: forexData } = useNetwork();
  const { get: getAssetData } = useNetwork();

  const { get, update, getAllIndexDbKeys } = useIndexedDB(
    INIT_CONFIGURATION_SAVE
  );

  const { STOCKS, WATCHLIST, FOREX, COMMODITIES, PRIVATES, MUSIC, SBA7, MHL, EF } =
    assetTabs;
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    socket?.on("ASSET_PRICE_DATA", (data) => {
      switch (data?.type) {
        case assestType.privates:
          handleSocketChange(data, setPrivatesState);
          break;
        case assestType.music:
          handleSocketChange(data, setMusics);
          break;
        case assestType.SBA7:
          handleSocketChange(data, setSba);
          break;
        case assestType.MHL:
          handleSocketChange(data, setManuHomeLoan);
          break;
        default:
          handleSocketChange(data, setWatchList, true);
          break;
      }
    });
    return () => {
      socket?.off("ASSET_PRICE_DATA");
    }
  }, [socket, activeCurrency?.id]);

  const handleSocketChange = (data: Json, setState: any, isWatchList?: boolean) => {
    setState((prev: Json[]) => {
      let tempState = [...(prev || [])];
      const updatedIndex = tempState?.findIndex((_data) => _data?.id === data?.id);
      if (updatedIndex > -1) {
        tempState[updatedIndex] = { ...tempState[updatedIndex], ...data };
        return [...tempState];
      }
      return [...prev];
    });
    if (!isWatchList) {
      setWatchList((prev) => {
        let tempState = [...(prev || [])];
        const updatedIndex = tempState?.findIndex((_data) => _data?.id === data?.id);
        if (updatedIndex > -1) {
          tempState[updatedIndex] = { ...tempState[updatedIndex], ...data };
          return [...tempState];
        }

        return prev
      })
    }
    if (activeCurrency?.id === data?.id) {
      setActiveCurrency((prev) => {
        return { ...prev, ...data }
      });
    }
  }

  useEffect(() => {
    setAssetLoading(true);
    resetMHLFilters();
    resetEFFilters();
    resetPrivateFilters();
    resetSba7Filters();
    Promise.all([
      getStocks(`${APIS.Explorers}?include=stocks`),
      getPrivates(`${APIS.Explorers}?include=privates`),
      getCommodites(`${APIS.Explorers}?include=commodities`),
      getForex(`${APIS.Explorers}?include=forex`),
      getMusic(`${APIS.Explorers}?include=music`),
      getSba(`${APIS.Explorers}?include=sba7`),
      getManuHomeLoan(`${APIS.Explorers}?include=manufactured_home_loans`),
      getEquipmentFinanceLoan(`${APIS.Explorers}?include=equipment_finance`),
      getWatchLists(`${APIS.Explorers}?include=watchlist`),
    ]).then(() => {
      promiseAllState.current = true
      setAssetLoading(false);
    })
  }, []);

  useEffect(() => {
    if (promiseAllState.current) {
      const key = Object.keys(watchLists?.data ?? []);
      setStocks(stockData?.data?.stocks || []);
      setSba(sbaData?.data?.sba7 ?? []);
      setManuHomeLoan(manuHomeLoanData?.data?.manufactured_home_loans ?? []);
      setEquipmentFinance(equipmentFinanceLoanData?.data?.equipment_finance ?? []);
      setMusics(musicData?.data?.music || []);
      setPrivatesState(privatesData?.data?.privates || []);
      setCommoditiesState(commoditesData?.data?.commodities || []);
      setForex(forexData?.data?.forex || []);
      setWatchList(watchLists?.data?.[key[0]] || []);

      update(stockData?.data?.stocks || [], STOCKS);
      update(privatesData?.data?.privates || [], PRIVATES);
      update(commoditesData?.data?.commodities || [], COMMODITIES);
      update(forexData?.data?.forex || [], FOREX);
      update(musicData?.data?.music || [], MUSIC);
      update(sbaData?.data?.sba7 || [], SBA7);
      update(manuHomeLoanData?.data?.manufactured_home_loans || [], MHL);
      update(equipmentFinanceLoanData?.data?.equipment_finance || [], EF);
      update(watchLists?.data?.[key[0]] || [], WATCHLIST);
      const { id, symbol, lender_loan_number } = activeCurrency;
      if (!(id || symbol || lender_loan_number) && !params.id) setDefaultTab(params.id);
    }
  }, [
    COMMODITIES,
    FOREX,
    MUSIC,
    PRIVATES,
    SBA7,
    STOCKS,
    commoditesData?.data?.commodities,
    forexData?.data?.forex,
    musicData?.data?.music,
    privatesData?.data?.privates,
    sbaData?.data?.sba7,
    manuHomeLoanData?.data?.manufactured_home_loans,
    equipmentFinanceLoanData?.equipment_finance,
    setCommoditiesState,
    setForex,
    setMusics,
    setPrivatesState,
    setSba,
    setStocks,
    stockData?.data,
    watchLists?.data,
    stockData?.data?.stocks,
    setWatchList,
    update,
    WATCHLIST,
    promiseAllState.current,
  ]);

  useEffect(() => {
    const id = params.id;
    let assetType = searchParams.get("type") ?? "Watchlist"; //Watchlist as default if search params has null type
    const activeTab = ACTIVE_ASSET_TAB_WITH_SPACE[assetType] || assetType;
    const tempKey = assetType === "Watchlist" ? "Watchlist" : activeTab;
    setActiveTab({
      key: tempKey,
      index: assetsTab.findIndex((value) => value === tempKey),
    });
    if (id) {
      setActiveCurrency({ ...defaultActiveCurrency, type: assetType } as any);
      getAssetData(`${APIS.ExchangeAssets}/${id}`).then((resp) => {
        if (resp?.message && resp?.message !== "ok" && assetType !== "Watchlist") {
          errorNotification(resp?.message);
        }
        if (resp?.data?.type) {
          setActiveCurrency({
            ...resp?.data,
            type: resp?.data?.type,
            marketPrice: resp.data?.amount,
          });
        } else {
          setDefaultTab(id, "", resp?.message);
        }
      }).catch((err) => {
        errorNotification(err?.message);
      });
    } else {
      setDefaultTab(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);


  const AvailableTabCheck = useCallback((indexDbKey: string) => {
    const tabsQuery = [assetTabsInclude.PRIVATES_QUERY, assetTabsInclude.MUSIC_QUERY, assetTabsInclude.SBA7_QUERY]
    const index = tabsQuery.findIndex((value) => value === indexDbKey)
    const nextActiveTab = tabsQuery[index + 1];
    if (nextActiveTab) {
      const activeTabKey = nextActiveTab.toUpperCase();
      setActiveTab({
        key: assetTabs[activeTabKey],
        index: assetsTab.findIndex((value) => value === assetTabs[activeTabKey]),
      })
      setDefaultTab("", nextActiveTab);
    } else {
      setActiveCurrency({} as IAssetsState);
    }
  }, [])


  const setDefaultTab = async (id: string | undefined, indexDbKey = "", errorMessage = "") => {
    const getIndexDbKeys: any = await getAllIndexDbKeys();
    if (!getIndexDbKeys?.length) return;
    let newID = id;
    const assetType =
      (!!newID && searchParams.get("type")) || indexDbKey || "Watchlist";
    const DBKey: string =
      assetType === "commodity" ? "COMMODITIES" : assetType.toUpperCase();
    get(assetTabs?.[DBKey]).then((data: any) => {
      if (indexDbKey && !data) return setActiveCurrency({} as IAssetsState);
      let dbData = [...(data || [])];
      if (dbData?.length && errorMessage === "Asset not found" && assetType === "Watchlist") {
        dbData = dbData.filter((item: any) => item?.id !== newID)
        update(dbData, WATCHLIST);
        newID = "";
      }
      if (dbData && dbData?.length) {
        let newActiveCurrency: any = {};
        if (!newID) {
          return navigate(`/exchange/${dbData?.[0]?.id}?type=${assetType}`);
        } else if (assetType === assestType?.commodities || assetType === assestType?.forex)
          newActiveCurrency = dbData?.find((item: any) => item?.symbol === newID);
        else {
          const assetData = dbData?.find((item: any) => item?.id === newID)
          if (!assetData) {
            return setActiveCurrency(({}) as IAssetsState);
          }
          return navigate(`/exchange/${assetData?.id}?type=${assetType}`);
        }
        setActiveCurrency((newActiveCurrency || {}) as IAssetsState);
      } else {
        AvailableTabCheck(indexDbKey)
      }
    });
  };

  // handle nav
  const handleClickNavigation = useCallback(
    (key: string, index: number) => {
      setActiveTab({ key, index });
      const element = tabRef.current[index]
      if (element !== null) {
        tabRef.current[index]?.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        })
      }
    },
    [setActiveTab]
  );

  const scrollButton = useCallback((value: number) => {
    if (currencyNavigationRef.current) {
      currencyNavigationRef.current.scrollLeft += value;
    }
  }, []);

  const handleScroll = useCallback((e: any) => {
    const navBar = e.target ?? {};
    const { scrollLeft } = navBar ?? {};
    const preList = document.querySelectorAll(".pre-arrow");
    const postList = document.querySelectorAll(".post-arrow");

    preList.forEach((pre) => {
      if (scrollLeft === 0) {
        pre?.classList.add(styles.hide);
      } else {
        pre?.classList.remove(styles.hide);
      }
    });

    postList.forEach((post) => {
      if (navBar?.scrollWidth <= scrollLeft + navBar?.clientWidth + 1) {
        post?.classList.add(styles.hide);
      } else {
        post?.classList.remove(styles.hide);
      }
    });
  }, []);

  const renderNavigation = useMemo(() => {
    return (
      <div
        className={`${styles.nav} currency__nav`}
        ref={currencyNavigationRef}
        onScroll={handleScroll}
      >
        <button className={styles.pre_button} onClick={() => scrollButton(-50)}>
          <i className={`ri-arrow-left-s-line ${styles.hide} pre-arrow`} />
        </button>
        {assetsTab.map((key: any, index: number) => (
          <button
            className={cn(
              styles.link,
              {
                [styles.active]: index === activeTab.index,
              },
              {
                [styles.sbaTab]: key === assestType?.SBA7,
              }
            )}
            ref={(el) => tabRef.current[index] = el}
            onClick={() => handleClickNavigation(key, index)}
            key={index}
          >
            {key === assetTabs.SBA7 ? SBA7A : key}
          </button>
        ))}
        <button className={styles.next_button} onClick={() => scrollButton(50)}>
          <i className={`ri-arrow-right-s-line post-arrow`} />
        </button>
      </div>
    );
  }, [activeTab, handleClickNavigation, handleScroll, scrollButton]);

  const mapAssetDataStateAsPerTab: any = {
    "Watchlist": watchList,
    "Private Stock": privateState,
    // "music": musics,
    "sba7": sba,
    "Manufactured Home Loan": manuHomeLoan,
    "Equipment Finance": equipmentFinance
  }

  const renderAssets = useMemo(() => {
    if (!mapAssetDataStateAsPerTab[activeTab.key]?.length) {
      return (
        <>
          <div className={styles.noData}>
            <div className={styles.noDataContent}>
              <Image
                fileName={`images/${darkMode.value ? "no_data_dark.svg" : "no_data_light.svg"
                }`}
              />
              {activeTab.key === "Watchlist" ? (
                <>
                  <div className={styles.noDataHeading}>
                    Your Watchlist is empty
                  </div>
                  <div className={styles.noDataPara}>
                    Add assets to your watchlist to keep track of their
                    performance.
                  </div>
                </>
              ) : (
                <div>No Data Found</div>
              )}
            </div>
          </div>
        </>
      );
    } else {
      return <div id="assets-scrollableDiv" className={styles.table}>{<Assets />}</div>;
    }
  }, [watchList, privateState, musics, sba, manuHomeLoan, equipmentFinance, activeTab])

  return (
    <div className={styles.currency}>
      <CurrencySearch />
      {renderNavigation}
      {assetLoading ? <SkeletonWatchList listsToRender={6} /> : renderAssets}
    </div>
  );
};
