import { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { APIS } from "constant";
import { ActiveAssetTab, initialQuerySbaState, initialQueryMHLoanState, SbaState, ManuHomeLoanState, initialQueryEFLoanState, EquipmentFinanceLoanState } from "states";
import { useNetwork } from "hooks";
import { SkeletonWatchList } from "components";
import { SbaItem } from "./sba-item";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";

import styles from "../Currency/Currency.module.sass";
import InfiniteScroll from "react-infinite-scroll-component";
import { getJsonToParams } from "@utils/common";
import { assetTabs } from "@views/exchange/constants";

export const Sba = () => {
  const activeTab = useRecoilValue(ActiveAssetTab);
  const isManuHomeLoan = activeTab.key === assetTabs.MHL
  const isEquipmentFinanceLoan = activeTab.key === assetTabs.EF
  const [filters, setFilters] = useRecoilState(isManuHomeLoan ? initialQueryMHLoanState : isEquipmentFinanceLoan ? initialQueryEFLoanState : initialQuerySbaState);

  //SBA7
  const { get: getSba7Data, loading: loadingISSBA } = useNetwork();
  const [sba, setSba] = useRecoilState(SbaState);
  const { get: getSba7 } = useNetwork();

  //Manu. home loan
  const { get: getManuHomeLoanData, loading: loadingISMHL } = useNetwork();
  const { get: getEquipmentFinanceLoanData, loading: loadingISEF } = useNetwork();
  const [manuHomeLoan, setManuHomeLoan] = useRecoilState(ManuHomeLoanState);
  const [equipmentFinanceLoan, setEquipmentFinanceLoan] = useRecoilState(EquipmentFinanceLoanState);

  const { get: getManuHomeLoan } = useNetwork();
  const { get: getEquipmentFinance } = useNetwork();

  const darkMode = useDarkMode(false);
  const refSBAInterval = useRef<any>(null);

  const mappedData = useMemo(() => {
    return {
      fetchMoreData: isManuHomeLoan ? getManuHomeLoanData : isEquipmentFinanceLoan ? getEquipmentFinanceLoanData : getSba7Data,
      loading: isManuHomeLoan ? loadingISMHL : isEquipmentFinanceLoan ? loadingISEF : loadingISSBA,
      setAssetData: isManuHomeLoan ? setManuHomeLoan : isEquipmentFinanceLoan ? setEquipmentFinanceLoan : setSba,
      assets: isManuHomeLoan ? manuHomeLoan : isEquipmentFinanceLoan ? equipmentFinanceLoan : sba,
      intervalFetch: isManuHomeLoan ? getManuHomeLoan : isEquipmentFinanceLoan ? getEquipmentFinance : getSba7,
    }
  }, [equipmentFinanceLoan, getEquipmentFinance, getEquipmentFinanceLoanData, getManuHomeLoan, getManuHomeLoanData, getSba7, getSba7Data, isEquipmentFinanceLoan, isManuHomeLoan, loadingISEF, loadingISMHL, loadingISSBA, manuHomeLoan, sba, setEquipmentFinanceLoan, setManuHomeLoan, setSba])


  // This code is for exchange explorer api polling
  const NewObjectWithNewIdAdded = useCallback(
    (previousArray: any, currentArray: any) => {
      const previousIds = previousArray.map((obj: any) => obj.id);
      const newSBA7Set = new Set(previousIds);
      if (!newSBA7Set.has(currentArray[0]?.id)) {
        return currentArray[0];
      } else {
        return "";
      }
    },
    []
  );

  useEffect(() => {
    refSBAInterval.current = setInterval(() => {
      mappedData?.intervalFetch(`${APIS.Explorers}?include=${isManuHomeLoan ? "manufactured_home_loans" : isEquipmentFinanceLoan ? "equipment_finance" : "sba7"}`).then((res: any) => {
        if (res?.message === "ok") {
          const newSBA7Asset = NewObjectWithNewIdAdded(mappedData.assets, isManuHomeLoan ? res?.data?.mhl || [] : isEquipmentFinanceLoan ? res?.data?.equipment_finance || [] : res?.data?.sba7);
          if (newSBA7Asset) {
            mappedData?.setAssetData((prev: any) => {
              return [newSBA7Asset, ...prev];
            });
          }
        }
      });
    }, 5000);
    return () => clearInterval(refSBAInterval.current);
  }, [mappedData.assets, isManuHomeLoan, isEquipmentFinanceLoan]);

  const handleFetchMore = useCallback(() => {
    const queryParams = { ...filters, offset: filters.offset + 1 };
    mappedData.fetchMoreData(`${APIS.Explorers}` + getJsonToParams(queryParams)).then((res: any) => {
      if (res) {
        mappedData?.setAssetData((prev: any) => {
          return [...prev, ...(isManuHomeLoan ? res?.data?.manufactured_home_loans || [] : isEquipmentFinanceLoan ? res?.data?.equipment_finance || [] : res?.data?.sba7)];
        });
      }
    });
    setFilters((prev) => ({ ...queryParams }));
  }, [filters, isManuHomeLoan,isEquipmentFinanceLoan]);

  return (
    <>
      {mappedData.assets?.length === 0 ? (
        <div className={styles.noData}>
          <div className={styles.noDataContent}>
            <Image
              fileName={`images/${darkMode.value ? "no_data_dark.svg" : "no_data_light.svg"
                }`}
            />
            <div>No Data Found</div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={mappedData.assets.length || 0}
          next={handleFetchMore}
          hasMore={
            mappedData.assets.length >= filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="assets-scrollableDiv"
        >
          {mappedData.assets?.map((data, index) => {
            return <SbaItem key={data?.id} index={index} {...(data ?? {})} />;
          })}
        </InfiniteScroll>
      )}

      {mappedData?.loading && <SkeletonWatchList listsToRender={4} />}
    </>
  );
};
