import React, { useEffect, useRef } from "react";
import Highcharts, { Options } from "highcharts";
import useDarkMode from "use-dark-mode";
import { useCurrency } from "@hooks/currency";
import { JsxElement } from "typescript";
import { Loader } from "@components/Loader2";

interface BarChartProps {
  title: string;
  categories: string[];
  series: any;
  height?: number;
  costBasis?: string;
  pnl?: string;
  pnlPercentage?: string;
  customHeader?: React.ReactNode; // Accept a custom header as a React node
  isChartLoading? : boolean
}

const BarChart: React.FC<BarChartProps> = ({
  title,
  categories,
  series,
  height = 300,
  costBasis,
  pnl,
  pnlPercentage,
  customHeader,
  isChartLoading = false
}) => {
  const darkMode = useDarkMode();
  const { formatCurrencyWithBillion } = useCurrency();
  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (chartRef.current) {
      const options: Options = {
        chart: {
          type: "column",
          reflow: true,
          backgroundColor: "transparent",
          height,
          style: {
            fontFamily: "Poppins",
          },
          width: null, // Let Highcharts automatically adjust the width to 100%
          zooming: {
            mouseWheel: {
              enabled: false,
            },
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: undefined, // Custom title rendered separately
        },
        xAxis: {
          categories,
          lineColor: "#444",
          labels: {
            style: {
              color: darkMode.value ? "#FFFFFF" : "#17191F",
            },
            formatter: function () {
              // Format the date to "DD/MM" (e.g., "02/08")
              const formattedDate = new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "2-digit",
              }).format(new Date(this.value)); // `this.value` refers to each x-axis category value (date)

              return formattedDate; // Return formatted date
            },
          },
        },
        yAxis: {
          title: { text: null },
          gridLineWidth: 0.6,
          gridLineColor: "#444",
          labels: {
            formatter: function (this: any) {
              return formatCurrencyWithBillion(this.value, 1);
            },
            style: {
              color: darkMode.value ? "#FFFFFF" : "#17191F",
            },
          },
        },
        legend: {
          align: "right", // Align legend to the right
          verticalAlign: "top", // Position at the top
          layout: "horizontal", // Display items in a vertical list
          x: 10,
          y: -5,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: "rgba(255, 255, 255, 0.12)",
          itemStyle: {
            color: darkMode.value ? "#BAC4DB" : "#5D657A",
            fontSize: "12px",
          },
          itemDistance: 8, // Reduce distance between legend items
          symbolPadding: 4, // Reduce space between symbol and text
          itemMarginTop: 0, // Adjust vertical spacing between rows of legend items
          itemMarginBottom: 0,
          itemHoverStyle: {
            color: darkMode.value ? "#FFFFFF" : "#17191F",
          },
        },
        exporting: { enabled: false },
        series,
        tooltip: {
          shared: true, // Display all series values for the same x-axis category
          useHTML: true, // Allow custom HTML
          backgroundColor: "#333",
          style: { color: "#FFF" },
          formatter: function (this: any) {
            // Use categories and format each series value
            //let tooltipContent = `<b>${this.x}</b><br/>`; // Display x-axis category

            // Format the date to "DD MMM, YYYY"
            const formattedDate = new Intl.DateTimeFormat("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }).format(new Date(this.x));

            let tooltipContent = `<b>${formattedDate}</b><br/>`; // Display formatted date

            this.points.forEach((point: any) => {
              const value = point.y; // Numeric value
              if (value !== 0) {
                // Only include points with non-zero values
                const isLoss = point.series.name.toLowerCase().includes("loss"); // Check if the series is "Loss"
                const formattedValue = isLoss
                  ? `<span style="color:#EB4747">$ -${formatCurrencyWithBillion(value, 2)}</span>` // Display Loss as negative
                  : `<span style="color:#31C46C"> $ +${formatCurrencyWithBillion(value, 2)}</span>`; // Display Profit as positive
                tooltipContent += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${formattedValue}</b><br/>`;
              }
            });

            return tooltipContent;
          },
        },
        loading: {
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            fontWeight: "bold",
            textAlign: "center",
            color: "#000",
          },
          labelStyle: {
            color: "#ffffff",
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0.5, // Add border width
            borderRadius: 3, // Optional: rounded corners for bars
          },
        },
      };

      const chart1: any = Highcharts.chart(chartRef.current, options);

      if (isChartLoading) {
        chart1.showLoading("Loading");
      } else {
        chart1.hideLoading();
      }
      window.addEventListener("resize", chart1);
    }

  }, [categories, series, height, darkMode, isChartLoading]);

  return (
    <div>
      {customHeader}
      {/* Chart Container */}
      <div ref={chartRef}></div>
   
    </div>
  );
};

export default BarChart;
