import { IActiveTabData } from "../type";

import React, { FC, Fragment, useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";

import { ActiveAssetTab, ActiveCurrencyState, EquipmentFinanceLoanState, ManuHomeLoanState, SbaState } from "states";
import { Price } from "../Price";
import { assestType, assetTabs } from "../../constants";
import styles from "./../Currency/Currency.module.sass";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { limitFirstPart, mergeStringWithSymbol } from "@utils/getString";
import { ACCREDITATION_LABEL } from "@views/exchange/components/Main/constants";

export const SbaItem: FC<any> = ({
  index,
  id,
  payment_amount,
  interest,
  borrower_name,
  lender_loan_number,
  symbol,
  name,
  image,
  currentYield,
  amount,
  currentLoanBalance,
  issuerDetails, sbaType,
  accreditationType
}) => {
  const activeTab = useRecoilValue(ActiveAssetTab);
  const isManuHomeLoan = activeTab.key === assetTabs.MHL
  const isEquipmentFinanceLoan = activeTab.key === assetTabs.EF
  const { issuerName } = issuerDetails || {}
  const SBA = useRecoilValue(SbaState);
  const manuHomeLoans = useRecoilValue(ManuHomeLoanState);
  const equipmentFinanceLoan = useRecoilValue(EquipmentFinanceLoanState);
  const [activeCurrency, setActiveCurrency] =
    useRecoilState(ActiveCurrencyState);
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (index: number, data: any) => {
      navigate(`/exchange/${data[index]?.id}?type=${isManuHomeLoan ? assestType.MHL : isEquipmentFinanceLoan ? assestType.EF : assestType.SBA7}`);
    },
    [navigate, isManuHomeLoan, isEquipmentFinanceLoan]
  );

  const [isError, setIsError] = useState(false);

  const getLogo = useCallback(
    ({ symbol, image }: any) => {
      return image && !isError ? (
        <img
          src={image}
          alt=""
          onError={() => setIsError(true)}
          className={styles.assetImage}
        />
      ) : (
        <div className={styles.assetLetter}>
          {symbol?.charAt(0).toUpperCase() ?? "?"}
        </div>
      );
    },
    [isError]
  );
  const loans = (isManuHomeLoan ? manuHomeLoans : isEquipmentFinanceLoan ? equipmentFinanceLoan : SBA).map((loan) => {
    return {
      ...loan,
      name: loan.borrower_name,
      symbol: loan.lender_loan_number?.toString(),
    };
  });

  const renderData = useMemo(() => {
    return (
      <Fragment >
        <div className={cn(`${styles.left}`, { [styles.activeSbaOrderBookLeft]: sbaType === "ORDERBOOK_SERIES" })}>
          <div className={cn(styles.assetImageContain, styles.sbaIcon)}>
            {getLogo({ symbol: name, image: image })}
            {!!ACCREDITATION_LABEL[accreditationType || ""] &&
              <img className={styles.accreditationLabel} src={`/media/icon/${accreditationType}.svg`} alt="Accreditation" />}
          </div>
          <div className={styles.symbol} data-tooltip-id={id}>
            {limitFirstPart(
              mergeStringWithSymbol(issuerName, symbol, "."),
              11,
              "."
            ) ?? "-"}
          </div>
          <ReactTooltip
            id={id}
            place="bottom"
            content={mergeStringWithSymbol(issuerName, symbol, ".")}
            className={"theme-tooltip"}
          />
          <div className={styles.compony_name}>
            {mergeStringWithSymbol(issuerName, name, " ") ?? "NA"}
          </div>
          <div data-tooltip-id={`${currentYield}_wps`} className={styles.compony_name}>
            {" "}
            WAC :{" "}
            <span>{(currentYield || 0)?.toFixed(2)}%</span>
          </div>
          <ReactTooltip
            id={`${currentYield}_wps`}
            place="bottom"
            content={"Weighted Average Coupon"}
            className={"theme-tooltip"}
            classNameArrow={styles.currentYield_tooltip}

          />
        </div>
        {sbaType === "ORDERBOOK_SERIES" ? <span className={styles.TypeOrderBookSeries}>Orderbook Series</span> : <Price
          marketPrice={amount}
          change={interest}
          changesPercentage={0}
          symbol={lender_loan_number}
          type={assestType.SBA7}
          currentLoanBalance={currentLoanBalance}
          showBondPointTooltip={true}
        />}
      </Fragment>
    );
  }, [accreditationType, amount, currentLoanBalance, currentYield, getLogo, id, image, interest, issuerName, lender_loan_number, name, sbaType, symbol]);

  return (
    <div
      onClick={() => handleRowClick(index, loans)}
      className={cn(styles.row, styles.sba, {
        [styles.active]: id === activeCurrency.id,
        [styles.activeSbaOrderBook]: id === activeCurrency.id && loans[index]?.sbaType === "ORDERBOOK_SERIES",
        // &&
        // lender_loan_number === activeCurrency.lender_loan_number &&
        // borrower_name === activeCurrency.borrower_name,
      })}
      key={index}
    >
      {renderData}
    </div>
  );
};
